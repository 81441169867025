<template>
  <WeContainer card="" v-if="ready">
    <div>
      <Navigation
        v-on:save-form="saveForm"
        v-bind:submit="submit"
        v-bind:errors="errors"
      />
      <Content ref="content" />
    </div>
    <WeSeoBar
      v-bind:focus-keyword="product.info.name"
      v-bind:metaTitle="product.detail.meta.title"
      v-bind:metaDescription="product.detail.meta.description"
      v-bind:description="product.detail.descriptions"
      v-bind:images="product.info.image.files"
      v-bind:slug="product.info.slug"
    />
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
const Navigation = () => import("./views/Navigation/Index");
const Content = () => import("./views/Content/Index");
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: false,
      errors: [],
    };
  },
  components: {
    Navigation,
    Content,
  },
  methods: {
    ...mapActions("product", ["create", "update", "load", "getById"]),
    ...mapActions("shared", ["getFeatureById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapActions({
      getDeliveryList: "deliveryTime/getList",
    }),
    validateForm() {
      this.errors = [];

      let informationValid = this.$refs.content.$refs.information.$v;
      informationValid.$touch();

      if (informationValid.$invalid) {
        this.errors.push("info");
      }
      this.validateMarketplace();

      return this.formAction();
    },
    validateMarketplace() {
      if (this.marketplaceIntegration) {
        let marketplaceValid =
          this.$refs.content.$refs.marketplace.$refs.content.$v;
        marketplaceValid.$touch();
        if (marketplaceValid.$invalid) {
          this.errors.push("marketplace");
        }
      }
    },
    formAction() {
      if (this.errors && this.errors.length) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
        return false;
      } else {
        return true;
      }
    },

    getProductById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.ready = true;
          },
          onFinish: () => {
            this.setDeliveryTimes();
          },
        });
      } else {
        this.ready = true;
        this.setDeliveryTimes();
      }
    },
    setDeliveryTimes() {
      if (
        this.product.detail.delivery_time &&
        !this.product.detail.delivery_time.length
      ) {
        this.getDeliveryList({
          onFinish: () => {
            this.product.detail.delivery_time = helper.clone(
              this.deliveryTime.list
            );
          },
        });
      }
    },
    addProduct() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.create({
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Ürün listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateProduct() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.update({
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Ürün listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    redirectToList() {
      this.$router.push("/products");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    saveForm() {
      if (this.$route.params.id) {
        this.updateProduct();
      } else {
        this.addProduct();
      }
    },
  },
  computed: {
    ...mapState(["product", "shared", "session", "deliveryTime"]),
    marketplaceIntegration() {
      const config = this.session.config;
      return (
        config["site.marketplace_integration"] == "1" ||
        config["site.marketplace_integration"] == 1
      );
    },
  },
  mounted() {
    this.load();
    this.getProductById();
  },
  watch: {
    "product.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.load();
          this.getProductById();
        }
      },
    },
  },
};
</script>
